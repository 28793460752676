import { withExtTenantIdRequest } from '@/utils/request';

// 修改手机号
export function distributorChangeMobileForMerchant (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorChangeMobile/changeMobileForMerchant`,
    method: 'post',
    data
  });
}
// 发送邮件
export function distributorChangeMobileSendEmail (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorChangeMobile/sendEmail`,
    method: 'post',
    data
  });
}
// 验证邮件认证是否成功
export function distributorChangeMobileValidEmail (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorChangeMobile/validEmail`,
    method: 'post',
    data
  });
}

// 验证新手机号
export function distributorChangeMobileValidNewMobile (data) { 
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorChangeMobile/validNewMobile`,
    method: 'post',
    data
  });
}


// 校验手机号和验证码是否匹配
export function distributorChangeMobileValidateVerifyCode (mobile, code) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorChangeMobile/validateVerifyCode?mobile=${mobile}&code=${code}`,
    method: 'post'
  });
}